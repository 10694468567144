// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dla-wielbicieli-plaz-js": () => import("./../../../src/pages/dla-wielbicieli-plaz.js" /* webpackChunkName: "component---src-pages-dla-wielbicieli-plaz-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-mapa-regionow-js": () => import("./../../../src/pages/mapa-regionow.js" /* webpackChunkName: "component---src-pages-mapa-regionow-js" */),
  "component---src-pages-regulamin-konkursu-js": () => import("./../../../src/pages/regulamin-konkursu.js" /* webpackChunkName: "component---src-pages-regulamin-konkursu-js" */),
  "component---src-pages-thank-you-newsletter-page-js": () => import("./../../../src/pages/thank-you-newsletter-page.js" /* webpackChunkName: "component---src-pages-thank-you-newsletter-page-js" */),
  "component---src-pages-wiadomosci-podroznicze-js": () => import("./../../../src/pages/wiadomosci-podroznicze.js" /* webpackChunkName: "component---src-pages-wiadomosci-podroznicze-js" */),
  "component---src-pages-wyszukiwanie-js": () => import("./../../../src/pages/wyszukiwanie.js" /* webpackChunkName: "component---src-pages-wyszukiwanie-js" */),
  "component---src-templates-akademia-ekspertow-js": () => import("./../../../src/templates/akademia-ekspertow.js" /* webpackChunkName: "component---src-templates-akademia-ekspertow-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-galeria-js": () => import("./../../../src/templates/galeria.js" /* webpackChunkName: "component---src-templates-galeria-js" */),
  "component---src-templates-imprezy-i-festiwale-js": () => import("./../../../src/templates/imprezy-i-festiwale.js" /* webpackChunkName: "component---src-templates-imprezy-i-festiwale-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-jak-dotrzec-do-tajlandii-js": () => import("./../../../src/templates/jak-dotrzec-do-tajlandii.js" /* webpackChunkName: "component---src-templates-jak-dotrzec-do-tajlandii-js" */),
  "component---src-templates-jedzenie-js": () => import("./../../../src/templates/jedzenie.js" /* webpackChunkName: "component---src-templates-jedzenie-js" */),
  "component---src-templates-kultura-js": () => import("./../../../src/templates/kultura.js" /* webpackChunkName: "component---src-templates-kultura-js" */),
  "component---src-templates-lokalizacja-js": () => import("./../../../src/templates/lokalizacja.js" /* webpackChunkName: "component---src-templates-lokalizacja-js" */),
  "component---src-templates-materialy-informacyjne-js": () => import("./../../../src/templates/materialy-informacyjne.js" /* webpackChunkName: "component---src-templates-materialy-informacyjne-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-pieniadze-i-waluta-js": () => import("./../../../src/templates/pieniadze-i-waluta.js" /* webpackChunkName: "component---src-templates-pieniadze-i-waluta-js" */),
  "component---src-templates-podroz-poslubna-js": () => import("./../../../src/templates/podroz-poslubna.js" /* webpackChunkName: "component---src-templates-podroz-poslubna-js" */),
  "component---src-templates-polityka-cookies-js": () => import("./../../../src/templates/polityka-cookies.js" /* webpackChunkName: "component---src-templates-polityka-cookies-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-province-js": () => import("./../../../src/templates/province.js" /* webpackChunkName: "component---src-templates-province-js" */),
  "component---src-templates-rekreacja-js": () => import("./../../../src/templates/rekreacja.js" /* webpackChunkName: "component---src-templates-rekreacja-js" */),
  "component---src-templates-tajskie-spa-js": () => import("./../../../src/templates/tajskie-spa.js" /* webpackChunkName: "component---src-templates-tajskie-spa-js" */),
  "component---src-templates-zabytki-centrum-js": () => import("./../../../src/templates/zabytki-centrum.js" /* webpackChunkName: "component---src-templates-zabytki-centrum-js" */),
  "component---src-templates-zabytki-js": () => import("./../../../src/templates/zabytki.js" /* webpackChunkName: "component---src-templates-zabytki-js" */),
  "component---src-templates-zabytki-polnoc-js": () => import("./../../../src/templates/zabytki-polnoc.js" /* webpackChunkName: "component---src-templates-zabytki-polnoc-js" */),
  "component---src-templates-zabytki-polnoc-wschod-js": () => import("./../../../src/templates/zabytki-polnoc-wschod.js" /* webpackChunkName: "component---src-templates-zabytki-polnoc-wschod-js" */),
  "component---src-templates-zabytki-poludnie-js": () => import("./../../../src/templates/zabytki-poludnie.js" /* webpackChunkName: "component---src-templates-zabytki-poludnie-js" */),
  "component---src-templates-zabytki-wschod-js": () => import("./../../../src/templates/zabytki-wschod.js" /* webpackChunkName: "component---src-templates-zabytki-wschod-js" */),
  "component---src-templates-zakupy-js": () => import("./../../../src/templates/zakupy.js" /* webpackChunkName: "component---src-templates-zakupy-js" */)
}

